import React from "react";
import { Link } from "gatsby";
import theme from "../theme/theme.yaml";
import HomeBody from "../components/Home/HomeBody";
import Seo from "../components/Seo";

const ContactPage = props => {
  return (
    <React.Fragment>
      <HomeBody theme={theme}>
        <div className="container">
          <div>
            <h4>Olá, meu nome é</h4>
            <h1>Décio Montanhani</h1>
            <p>
              Eu ajudo empresas a contruírem apps <strong>incríveis.</strong>
            </p>
            <Link to="/blog">
              <a className="submitButton">Conheça o meu blog</a>
            </Link>
          </div>
        </div>
      </HomeBody>
      <Seo pageTitle="Home" />

      <style jsx>{`
        h1 {
          font-size: ${theme.font.size.xxxl};
          margin: 10px 0 20px 0;
          animation-name: headlineEntry;
          animation-duration: 0;
          color: ${theme.color.neutral.gray.j};
        }

        .submitButton {
          color: white;
          height: auto;
          font-family: Open Sans;
          font-size: 1.2em;
          font-weight: 400;
          padding: 0.5em 3em;
          border-radius: 5px;
          background: ${theme.color.brand.primary};
          border: 1px solid ${theme.color.brand.primary};
        }
        .submitButton:hover {
          background: ${theme.color.brand.primaryDark};
          cursor: pointer;
        }

        p {
          font-size: ${theme.font.size.s};
          margin-bottom: 50px;
        }

        @media (max-width: 481px) {
          h1 {
            font-size: ${theme.font.size.xxl};
          }
        }

        @media (max-width: 350px) {
          .submitButton {
            font-size: ${theme.font.size.xs};
          }
        }

        h4 {
          font-size: ${theme.font.size.l};
          color: ${theme.color.brand.primary};
        }

        .container {
          padding-top: 20px;
          display: flex;
          height: 100%;
          flex: 1;
          flex-direction: column;
          border-width: 5px;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </React.Fragment>
  );
};

ContactPage.propTypes = {};

export default ContactPage;
